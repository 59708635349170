import axios from 'axios'
import { Loading } from 'element-ui' // 导入 Element UI 的 Loading 服务
import store from '@/store' // 导入 Vuex store
import router from '@/router' // 导入 Vue Router
import { getInfo, removeInfo } from '@/utils/storage'

// 动态获取基础 URL
// const getBaseURL = () => {
//   const hostname = window.location.hostname

//   if (hostname === 'fohufo.com/fo') {
//     return 'https://fohufo.com/fo'
//   } else if (hostname === 'test.fohufo.com') {
//     return 'https://test.fohufo.com/fo'
//   } else {
//     return 'https://default.example.com' // 默认 URL 或处理未知域名
//   }
// }

const instance = axios.create({
  baseURL: 'https://fohufo.com/fo', // 设置 baseURL，根据实际情况修改
  timeout: 7000 // 设置请求超时时间
})

let loadingInstance = null
let isRefreshing = false
let requestsQueue = []

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    loadingInstance = Loading.service({
      text: 'Loading...',
      fullscreen: true,
      background: 'rgba(0, 0, 0, 0.7)'
    })

    const token = store.state.user.token
    if (token) {
      config.headers.platform = 'fo' // 固定值
      config.headers.Authorization = `${token}`
    }
    return config
  },
  function (error) {
    if (loadingInstance) loadingInstance.close()
    return Promise.reject(error)
  }
)

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    if (loadingInstance) loadingInstance.close()
    return response.data
  },
  async function (error) {
    if (loadingInstance) loadingInstance.close()

    const originalRequest = error.config

    if (error.response && error.response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true

        try {
          const { refreshToken } = getInfo() // 获取 refreshToken
          const response = await axios.post('/user/refreshToken', {
            clientId: 'fohufo_app',
            loginType: 1,
            refreshToken
          })
          console.log('无感刷新', response)

          const newToken = response.data.access_token
          store.commit('ecommerceft_info', newToken) // 更新 token
          originalRequest.headers.Authorization = newToken

          requestsQueue.forEach(cb => cb(newToken))
          requestsQueue = []
          return instance(originalRequest)
        } catch (refreshError) {
          removeInfo() // 删除本地存储的用户信息
          router.push('/login')
          return Promise.reject(refreshError)
        } finally {
          isRefreshing = false
        }
      } else {
        return new Promise(resolve => {
          requestsQueue.push(token => {
            originalRequest.headers.Authorization = token
            resolve(instance(originalRequest))
          })
        })
      }
    }

    return Promise.reject(error)
  }
)

export default instance
