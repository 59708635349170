<template>
  <div class="Productdetails">
    <div class="Productdetailsheader">
      <el-page-header @back="goBack" title="return"></el-page-header>
    </div>
    <div class="content">
      <!-- 左侧360图 -->
      <div class="contentleft">
        <img :src="selectedImage" class="banner-img" />
      </div>

<!-- 用于展示360图的容器 -->
      <!-- <div class="contentleft">
        <div id="viewer" class="banner-img"></div>
      </div> -->

      <div class="contentright">
        <div class="Productdetailsname">
          {{ productName }}
        </div>
        <!-- 价格 -->
        <div class="Productdetailsprice">
          <span class="new-price">${{ selectedNewPrice }}</span>
          <!-- <span class="old-price">${{ selectedOldPrice }}</span> -->
        </div>
        <div v-for="(sku, skuIndex) in skuList" :key="skuIndex" class="Productdetailsku">
          <div class="propertyname">{{ sku.key }}:</div>
          <ul>
            <li
              v-for="(property, propertyIndex) in sku.value"
              :key="propertyIndex"
              :class="{ selected: selectedProperties[skuIndex] === propertyIndex }"
              @click="selectProperty(skuIndex, propertyIndex)"
            >
              {{ property }}
            </li>
          </ul>
        </div>
        <div class="cartnum">
          Number: <el-input-number style="margin-left: 20px;" v-model="num" @change="handleChange" :min="1" :max="100" label="描述文字"></el-input-number>
        </div>
        <div class="prodetailbtn">
          <el-button type="danger" class="btn" @click="getaddcart" round>Add custom lists</el-button>
          <!-- <el-button type="info" class="btn" round>Buy now</el-button> -->
          <el-button type="warning" class="btn" @click="getCollection" round>Collection</el-button>
        </div>
      </div>
    </div>

    <!-- Tab栏切换 -->
    <el-tabs v-model="activeTab" type="card" class="product-tabs">
      <!-- 商品详细参数 -->
      <el-tab-pane label="Product Details" name="details">
        <h2>Product Details</h2>
        <div class="Productdetailstable">
          <table>
            <tbody>
              <tr v-for="(item, index) in productParams" :key="index">
                <td>{{ item.key }}</td>
                <td>{{ item.value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-tab-pane>
      <!-- 商品详情图片 -->
      <el-tab-pane label="Product Images" name="images">
        <h2>Product Images</h2>
        <div class="ProductImages">
          <img v-for="(item, index) in imageUrls" :key="index" :src="item" :alt="item" />
        </div>
      </el-tab-pane>
      <!-- 用户评价 -->
      <!-- <el-tab-pane label="User Reviews" name="reviews">
        <div class="UserReviews">
          <h2>User Reviews ({{ reviews.length }})</h2>
          <div class="reviewsScoll">
            <div v-for="(review, index) in reviews" :key="index" class="review-item">
            <img :src="review.avatar" class="review-avatar" />
            <div class="review-content">
              <div class="review-name">{{ review.name }}</div>
              <div class="review-text">{{ review.text }}</div>
              <div class="review-images">
                <img v-for="(img, imgIndex) in review.images" :key="imgIndex" :src="img" class="review-image" />
              </div>
            </div>
          </div>
          </div>
        </div>
      </el-tab-pane> -->
    </el-tabs>

    <BottomInformation></BottomInformation>
  </div>
</template>

<script>
import BottomInformation from '@/components/BottomInformation.vue'
import { getdetail } from '@/api/classify/classify'
import { PostAddCart, PostCollection } from '@/api/cart/cart'
// import { Viewer } from 'photo-sphere-viewer'
// import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'
export default {
  name: 'ProductdetailsIndex',
  components: {
    BottomInformation
  },
  data () {
    return {
      id: this.$route.params.id,
      productsid: '',
      skuList: [], // 存储 SKU 规格列表
      selectedProperties: [], // 记录用户选择的规格
      num: 1, // 默认数量
      baseImageUrl: '', // 底图
      selectedNewPrice: '', // 选中的新价格
      selectedOldPrice: '', // 选中的旧价格
      selectedImage: '', // 选中的图片
      productName: '', // 商品名称
      productParams: [], // 商品参数
      imageUrls: [], // 商品图片,
      skuid: '',
      activeTab: 'details' // 当前激活的 Tab
      // reviews: [
      //   {
      //     avatar: require('@/img/avatars/1.jpg'),
      //     name: 'Emma Johnson',
      //     text: 'Great product! Highly recommend.',
      //     images: [require('@/img/banner/banner1.png'), require('@/img/banner/banner2.png')]
      //   },
      //   {
      //     avatar: require('@/img/avatars/2.jpg'),
      //     name: 'Olivia Smith',
      //     text: 'The results are fantastic and easy to assemble',
      //     images: [require('@/img/banner/banner3.png')]
      //   },
      //   {
      //     avatar: require('@/img/avatars/3.jpg'),
      //     name: 'Liam Brown',
      //     text: 'The haute couture stuff was perfect, perfect to what I had in mind',
      //     images: [require('@/img/banner/banner4.png'), require('@/img/homeimg/Dining.png')]
      //   },
      //   {
      //     avatar: require('@/img/avatars/1.jpg'),
      //     name: 'Emma Johnson',
      //     text: 'Great product! Highly recommend.',
      //     images: [require('@/img/banner/banner1.png'), require('@/img/banner/banner2.png')]
      //   },
      //   {
      //     avatar: require('@/img/avatars/2.jpg'),
      //     name: 'Olivia Smith',
      //     text: 'The results are fantastic and easy to assemble',
      //     images: [require('@/img/banner/banner3.png')]
      //   },
      //   {
      //     avatar: require('@/img/avatars/3.jpg'),
      //     name: 'Liam Brown',
      //     text: 'The haute couture stuff was perfect, perfect to what I had in mind',
      //     images: [require('@/img/banner/banner4.png'), require('@/img/homeimg/Dining.png')]
      //   },
      //   {
      //     avatar: require('@/img/avatars/1.jpg'),
      //     name: 'Emma Johnson',
      //     text: 'Great product! Highly recommend.',
      //     images: [require('@/img/banner/banner1.png'), require('@/img/banner/banner2.png')]
      //   },
      //   {
      //     avatar: require('@/img/avatars/2.jpg'),
      //     name: 'Olivia Smith',
      //     text: 'The results are fantastic and easy to assemble',
      //     images: [require('@/img/banner/banner3.png')]
      //   },
      //   {
      //     avatar: require('@/img/avatars/3.jpg'),
      //     name: 'Liam Brown',
      //     text: 'The haute couture stuff was perfect, perfect to what I had in mind',
      //     images: [require('@/img/banner/banner4.png'), require('@/img/homeimg/Dining.png')]
      //   }
      // ]
    }
  },
  created () {
    this.getdetail() // 页面加载时获取商品详情
  },
  // mounted () {
  //   this.selectedImage = require('@/img/VR/4.jpg')
  //   // 初始化 Photo Sphere Viewer
  //   this.viewer = new Viewer({
  //     panorama: this.selectedImage, // 360 图像
  //     container: document.getElementById('viewer'), // 容器
  //     navbar: true, // 是否显示控制导航
  //     loadingImg: 'loading-spinner.gif' // 可选的加载图片
  //   })
  // },

  // beforeDestroy () {
  //   // 清理 viewer
  //   if (this.viewer) {
  //     this.viewer.destroy()
  //   }
  // },
  methods: {
    goBack () {
      this.$router.go(-1) // 返回上一级
    },
    async getdetail () {
      const res = await getdetail(this.id) // 获取商品详情
      console.log(res)

      const { id, SpecsList, productsName, imageUrls, productParams, skuData, baseImageUrl } = res.data.skuProperties

      this.skuList = SpecsList.map(spec => ({
        key: spec.key,
        value: spec.value
      }))
      this.baseImageUrl = baseImageUrl
      console.log(this.baseImageUrl)

      this.productsid = id
      this.productName = productsName
      this.imageUrls = imageUrls
      this.productParams = productParams
      this.skuData = skuData // 保存 SKU 数据
      this.selectedProperties = SpecsList.map(() => 0)
      console.log(this.productsid)
      console.log(this.num)

      // 默认选择第一个 SKU 并更新显示
      this.updateSelectedSku()
      // this.viewer.setPanorama(this.selectedImage)
    },
    selectProperty (skuIndex, propertyIndex) {
      this.$set(this.selectedProperties, skuIndex, propertyIndex)
      this.updateSelectedSku() // 每次选择规格后更新显示的 SKU
    },
    updateSelectedSku () {
      const selectedSku = this.skuList.reduce((selected, sku, index) => {
        selected[sku.key] = sku.value[this.selectedProperties[index]]
        return selected
      }, {})

      const matchedSku = this.findMatchingSku(selectedSku) // 查找匹配的 SKU
      if (matchedSku) {
        // console.log('Matched SKU:', matchedSku) // 打印匹配到的 SKU 信息
        this.skuid = matchedSku.id
        this.selectedNewPrice = matchedSku.productsNewPrice
        this.selectedOldPrice = matchedSku.productsOldPrice
        // console.log('选择规格图片', this.selectedImage)
        this.selectedImage = matchedSku.image || this.imageUrls[0] // 如果 SKU 没有图片，使用默认图片
        // this.viewer.setPanorama(this.selectedImage)
      }
    },
    findMatchingSku (selectedSku) {
      return this.skuData.find(sku => {
        return Object.keys(selectedSku).every(key => sku[key] === selectedSku[key])
      })
    },
    handleChange (value) {
      console.log(value)
    },
    // 添加购物车
    async getaddcart () {
      console.log(this.skuid)
      console.log(this.num)
      const res = await PostAddCart(this.skuid, this.num)
      console.log(res)
      if (res.code !== 200) {
        return this.$message.error('Failed to add cart')
      } else {
        this.$message.success('The cart was added successfully')
      }
    },
    // 添加收藏
    async getCollection () {
      console.log(this.num)
      const res = await PostCollection(this.skuid, this.num)
      console.log(res)
      if (res.code !== 200) {
        return this.$message.error('Failed to add favorites')
      } else {
        this.$message.success('The collection was added successfully')
      }
    }
  }
}
</script>

<style scoped>
.product-tabs {
  width: 1520px;
  margin-top: 20px;
  margin-left: 200px;
}

.el-tabs__header {
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.el-tab-pane {
  padding: 20px;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item:not(.is-disabled):hover {
    color: black !important;
}

::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: black !important;
    background-color: #FFF !important;
    border-right-color: #DCDFE6 !important;
    border-left-color: #DCDFE6 !important;
}

/* 其他样式保持不变 */
.Productdetailsheader {
  width: 100%;
  height: 30px;
}

.content {
  margin: auto;
  width: 1510px;
  height: 660px;
  margin-bottom: 20px; /* 调整此值以改变content底部的间隔 */
  border-bottom: 2px solid #99a9bf;
  /* background-color: #99a9bf; */
}

.contentleft {
  float: left;
  width: 604px;
  height: 550px;
}
.banner-img{
  width: 100%;
  height: 100%;
}
.el-carousel {
  width: 604px;
  height: 550px;
}

.el-carousel__item {
  width: 604px;
  height: 550px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 500px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.contentright {
  float: right;
  width: 846px;
  /* height: 550px; */
  /* background-color: #475669; */
}

.el-carousel .el-carousel__indicator {
  margin-bottom: 10px;
}

.Productdetailsname {
  width: 100%;
  height: 90px;
  font-size: 18px;
  font-weight: bold;
}

.Productdetailsprice {
  width: 100%;
  height: 40px;
  font-size: 22px;
  margin-top: 10px; /* 增加与商品名称的间隔 */
}

.new-price {
  color: orange;
  font-weight: bold;
  font-size: 28px;
}

.old-price {
  font-size: 17px;
  color: #979899;
  text-decoration: line-through;
  margin-left: 20px;
}

.Productdetailstable {
  width: 1410px;
  /* margin: auto; */
  margin-top: 20px; /* 增加与上方内容的间隔 */
}

.Productdetailstable table {
  width: 100%;
  border-collapse: collapse;
}

.Productdetailstable td {
  padding: 5px; /* 调整单元格内边距以减小大小 */
  border: 1px solid #ccc;
  font-size: 14px; /* 调整字体大小以减小单元格的高度 */
}

.Productdetailsku {
  margin-top: 10px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.propertyname {
  font-weight: bold;
  font-size: 18px;
}

.Productdetailsku ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.Productdetailsku ul li {
  font-size: 18px;
  list-style: none;
  margin: 10px 10px;
  padding: 0 10px;
  text-align: center;
  /* width: 80px; */
  height: 40px;
  border: 1px solid #979899;
  display: flex; /* Use Flexbox */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  cursor: pointer;
}

.Productdetailsku ul li.selected {
  background-color: white;
  /* color: white; */
  border: 4px solid black;
}

.cartnum {
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
  height: 50px;
  font-weight: bold;
}

.prodetailbtn {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.prodetailbtn .btn {
  width: 48%;
}

/* 新增样式 */
.ProductImages {
  display: flex;
  flex-direction: column; /* 使图片竖向排列 */
  align-items: center; /* 让图片水平居中 */
  margin-top: 20px; /* 增加与上方内容的间隔 */
}

.ProductImages img {
  width: 48%;
  height: auto;
  margin-bottom: 10px; /* 增加图片之间的间隔 */
}

.UserReviews {
  /* margin: 20px auto; */
  width: 80%;
}

.UserReviews h2 {
  font-size: 24px;
  margin-bottom: 20px;
}
.reviewsScoll{
  width: 1510px;
  height: 800px;
  overflow-y: auto;
}
.review-item {
  width: 1310px;
  display: flex;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  background: #f9f9f9;
}

.review-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.review-content {
  flex: 1;
}

.review-name {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}

.review-text {
  font-size: 14px;
  margin-bottom: 10px;
}

.review-images {
  display: flex;
  flex-wrap: wrap;
}

.review-image {
  width: 140px;
  height: 80px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
}
/* 修改触摸样式 */
.product-tabs /deep/ .el-tabs__item.is-active,
.product-tabs /deep/ .el-tabs__item:hover {
  background-color: #555; /* 触摸或选中Tab的背景色 */
  color: #fff; /* 触摸或选中Tab的字体颜色 */
}
@media (max-width: 768px) {
  .product-tabs h2{
    font-size: 20px;
  }
  .UserReviews h2 {
    font-size: 20px;
  }

  .review-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .review-avatar {
    width: 40px;
    height: 40px;
  }

  .review-content {
    margin-left: 0;
  }

  .review-images {
    justify-content: center;
  }

  .review-image {
    width: 60px;
    height: 60px;
  }
}
</style>
